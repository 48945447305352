import { Typography, Theme, useMediaQuery, Box } from '@mui/material';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from '../../icons/video/AvatarIcon';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import PinIcon from './PinIcon/PinIcon';
import ScreenShareIcon from '../../icons/video/ScreenShareIcon';

import useIsTrackSwitchedOff from '../../../hooks/twilio/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../../hooks/twilio/usePublications/usePublications';
import useTrack from '../../../hooks/twilio/useTrack/useTrack';
import useParticipantIsReconnecting from '../../../hooks/twilio/useParticipantIsReconnecting/useParticipantIsReconnecting';

interface ParticipantInfoProps {
    participant: Participant;
    children: React.ReactNode;
    onClick?: () => void;
    isSelected?: boolean;
    isLocalParticipant?: boolean;
    hideParticipant?: boolean;
}

export default function ParticipantInfo({ participant, onClick, isSelected, children, isLocalParticipant, hideParticipant }: ParticipantInfoProps) {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const publications = usePublications(participant);

    const audioPublication = publications.find((p) => p.kind === 'audio');
    const videoPublication = publications.find((p) => !p.trackName.includes('screen') && p.kind === 'video');

    const isVideoEnabled = Boolean(videoPublication);
    const isScreenShareEnabled = publications.find((p) => p.trackName.includes('screen'));

    const videoTrack = useTrack(videoPublication);
    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

    const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);

    return (
        <Box
            id='participant-info'
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                height: 0,
                overflow: 'hidden',
                marginBottom: '0.5em',
                borderRadius: '4px',
                border: `${2}px solid rgb(245, 248, 255)`,
                paddingTop: `calc(${(9 / 16) * 100}% - ${2}px)`,
                background: 'black',
                // '& video': {
                //     filter: 'none',
                //     objectFit: 'contain !important'
                // },
                ...(isMobile && {
                    height: 90,
                    width: `${(90 * 16) / 9}px`,
                    marginRight: '8px',
                    marginBottom: '0',
                    fontSize: '12px',
                    paddingTop: `${90 - 2}px`
                }),
                ...(hideParticipant && {
                    display: 'none'
                })
            }}
            onClick={onClick}
            data-cy-participant={participant.identity}
        >
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    width: '100%',
                    background: 'transparent',
                    top: 0
                }}
            >
                <NetworkQualityLevel participant={participant} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        bottom: 0,
                        left: 0
                    }}
                >
                    {isScreenShareEnabled && (
                        <Box
                            component='span'
                            sx={{
                                background: 'rgba(0, 0, 0, 0.5)',
                                padding: '0.18em 0.3em',
                                marginRight: '0.3em',
                                display: 'flex',
                                '& path': {
                                    fill: 'white'
                                }
                            }}
                        >
                            <ScreenShareIcon />
                        </Box>
                    )}
                    <Box
                        component='span'
                        sx={{
                            background: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            padding: '0.18em 0.3em',
                            margin: 0,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <AudioLevelIndicator audioTrack={audioTrack} />
                        <Typography
                            variant='body1'
                            sx={{
                                color: 'white',
                                ...(isMobile && { fontSize: '0.75rem' })
                            }}
                            component='span'
                        >
                            {participant.identity}
                            {isLocalParticipant && ' (You)'}
                        </Typography>
                    </Box>
                </Box>
                <div>{isSelected && <PinIcon />}</div>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
            >
                {(!isVideoEnabled || isVideoSwitchedOff) && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'black',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: 1,
                            ...(isMobile && {
                                '& svg': {
                                    transform: 'scale(0.7)'
                                }
                            })
                        }}
                    >
                        <AvatarIcon />
                    </Box>
                )}
                {isParticipantReconnecting && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'rgba(40, 42, 43, 0.75)',
                            zIndex: 1
                        }}
                    >
                        <Typography
                            variant='body1'
                            sx={{
                                color: 'white',
                                ...(isMobile && { fontSize: '0.75rem' })
                            }}
                        >
                            Reconnecting...
                        </Typography>
                    </Box>
                )}
                {children}
            </Box>
        </Box>
    );
}
