import { Button, useMediaQuery, Theme, Tooltip } from '@mui/material';
import MicSharpIcon from '@mui/icons-material/MicSharp';
import MicOffSharpIcon from '@mui/icons-material/MicOffSharp';

import useLocalAudioToggle from '../../../../hooks/twilio/useLocalAudioToggle/useLocalAudioToggle';
import { useVideoContext } from '../../../../providers/video';

interface ToggleAudioButtonProps {
    isDisabled?: boolean;
    includeBorder?: boolean;
}

export default function ToggleAudioButton({ isDisabled = false, includeBorder = false }: ToggleAudioButtonProps) {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const { localTracks } = useVideoContext();
    const hasAudioTrack = localTracks.some((track) => track.kind === 'audio');

    let tooltipMessage = isAudioEnabled ? 'Mute' : 'Unmute';

    return (
        <Tooltip placement='top' title={tooltipMessage} arrow>
            <span>
                <Button
                    sx={{
                        margin: '1em 0',
                        width: '100%',
                        ...(isMobile && {
                            padding: '0.8em 0',
                            margin: 0
                        }),
                        ...(!includeBorder && {
                            color: 'white'
                        }),
                        ...(includeBorder && {
                            border: '2px solid'
                        })
                    }}
                    onClick={toggleAudioEnabled}
                    disabled={!hasAudioTrack || isDisabled}
                    startIcon={isAudioEnabled ? <MicSharpIcon fontSize={'large'} /> : <MicOffSharpIcon />}
                    data-cy-audio-toggle
                ></Button>
            </span>
        </Tooltip>
    );
}
