import { Theme, Button, Tooltip, useMediaQuery } from '@mui/material';

import ScreenShareSharpIcon from '@mui/icons-material/ScreenShareSharp';

import useScreenShareParticipant from '../../../../hooks/twilio/useScreenShareParticipant/useScreenShareParticipant';
import { useVideoContext } from '../../../../providers/video';

export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
    const screenShareParticipant = useScreenShareParticipant();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { toggleScreenShare, isSharingScreen } = useVideoContext();
    const disableScreenShareButton = Boolean(screenShareParticipant);
    const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

    let tooltipMessage = isSharingScreen ? 'Stop Sharing' : 'Share Your Screen';

    if (disableScreenShareButton) {
        tooltipMessage = SHARE_IN_PROGRESS_TEXT;
    }

    if (!isScreenShareSupported) {
        tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
    }

    return (
        <Tooltip
            title={tooltipMessage}
            placement='top'
            PopperProps={{ disablePortal: true }}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
            arrow
        >
            <Button
                onClick={toggleScreenShare}
                disabled={isDisabled}
                startIcon={<ScreenShareSharpIcon style={{ color: 'white' }} />}
                data-cy-share-screen
                sx={{
                    margin: '1em 0',
                    ...(isMobile && { padding: '0.8em 0', margin: 0 }),
                    ...(isDisabled && {
                        color: '#bbb',
                        '& svg *': {
                            fill: '#bbb'
                        }
                    })
                }}
            ></Button>
        </Tooltip>
    );
}
