import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import { DialogContent, Typography, Divider, Dialog, DialogActions, Button, Theme, DialogTitle, Box } from '@mui/material';
import VideoInputList from './VideoInputList/VideoInputList';

export default function DeviceSelectionDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    margin: {
                        xs: '16px'
                    }
                }
            }}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Audio and Video Settings</DialogTitle>
            <Divider />
            <DialogContent
                sx={{
                    width: {
                        xs: 'calc(100vw - 32px)',
                        sm: '600px'
                    },
                    minHeight: '400px',
                    '& .inputSelect': {
                        width: 'calc(100% - 35px)'
                    }
                }}
            >
                <Box
                    sx={{
                        margin: '2em 0 0.8em',
                        '&:first-of-type': {
                            margin: '1em 0 2em 0'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            marginBottom: '1.3em',
                            fontSize: '1.1rem'
                        }}
                        variant='h6'
                    >
                        Video
                    </Typography>
                    <VideoInputList />
                </Box>
                <Divider />
                <Box
                    sx={{
                        margin: '2em 0 0.8em',
                        '&:first-of-type': {
                            margin: '1em 0 2em 0'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            marginBottom: '1.3em',
                            fontSize: '1.1rem'
                        }}
                        variant='h6'
                    >
                        Audio
                    </Typography>
                    <AudioInputList />
                </Box>
                <Box
                    sx={{
                        margin: '2em 0 0.8em',
                        '&:first-of-type': {
                            margin: '1em 0 2em 0'
                        }
                    }}
                >
                    <AudioOutputList />
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button sx={{ float: 'right' }} color='primary' variant='contained' onClick={onClose}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
}
