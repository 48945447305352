import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import useRoomState from '../../../hooks/twilio/useRoomState/useRoomState';

export default function ReconnectingNotification() {
    const roomState = useRoomState();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        enqueueSnackbar('Reconnecting to room...', { variant: 'error' });
    }, [enqueueSnackbar]);
    return <></>;
}
