import { Button, Typography, Grid, Hidden, Box } from '@mui/material';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../../utilities/video/videoDefaults';
import Menu from './Menu/Menu';
import useRoomState from '../../../hooks/twilio/useRoomState/useRoomState';
import { useVideoContext } from '../../../providers/video';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import ToggleFullScreenButton from '../Buttons/FullScreenButton/ToggleFullScreenButton';
import { useVideoState } from '../../../providers/twilio';

export default function MenuBar() {
    const { isSharingScreen, toggleScreenShare, room } = useVideoContext();
    const { appointment } = useVideoState();
    const roomState = useRoomState();
    const isReconnecting = roomState === 'reconnecting';

    return (
        <>
            <Box
                id='twilio-footer'
                component='footer'
                sx={{
                    bottom: 0,
                    height: '72px',
                    display: 'flex',
                    padding: { xs: 0, md: '0 1.43em' },
                    zIndex: 10
                }}
            >
                <Grid id='twilio-action-container' container justifyContent='space-around' alignItems='center'>
                    <Hidden smDown>
                        <Grid style={{ flex: 1 }}>
                            <Typography variant='body1'>{appointment?.type}</Typography>
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <Grid container justifyContent='center'>
                            <ToggleAudioButton isDisabled={isReconnecting} />
                            <ToggleVideoButton isDisabled={isReconnecting} />
                            <ToggleFullScreenButton isDisabled={isReconnecting} />
                            {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
                            <Hidden smDown>
                                <Menu />
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid style={{ flex: 1 }}>
                        <Grid container justifyContent='flex-end'>
                            <EndCallButton />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
