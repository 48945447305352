import { Box, Theme, useMediaQuery } from '@mui/material';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';

export default function Room() {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const totalMobileSidebarHeight = `${90 + 8 * 2 + 2}px`;
    return (
        <Box
            id='twilio-room'
            sx={{
                position: 'relative',
                height: '100%',
                display: 'grid',
                gridTemplateColumns: `1fr ${300}px`,
                gridTemplateRows: '100%',
                ...(isMobile && {
                    gridTemplateColumns: `100%`,
                    gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`
                })
            }}
        >
            <MainParticipant />
            <ParticipantList />
        </Box>
    );
}
