import { useCallback, useEffect } from 'react';

import { Button, useMediaQuery, Theme, Tooltip } from '@mui/material';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import { useVideoContext } from '../../../../providers/video';

interface ToggleFullScreenButtonProps {
    isDisabled?: boolean;
}

export default function ToggleFullScreenButton({ isDisabled }: ToggleFullScreenButtonProps) {
    const { fullScreenMode, setFullScreenMode } = useVideoContext();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    let toolTipMessage = fullScreenMode ? 'Minimize' : 'Full Screen';
    const toggleFullScreen = useCallback(() => {
        setFullScreenMode(!fullScreenMode);
    }, [fullScreenMode, setFullScreenMode]);

    return (
        <Tooltip placement='top' title={toolTipMessage} arrow>
            <Button
                sx={{
                    color: 'white',
                    margin: '1em 0',
                    ...(isMobile && { padding: '0.8em 0', margin: 0 })
                }}
                startIcon={fullScreenMode ? <FullscreenExitOutlinedIcon /> : <FullscreenOutlinedIcon />}
                data-cy-audio-toggle
                onClick={toggleFullScreen}
            ></Button>
        </Tooltip>
    );
}
