import Participant from '../Participant/Participant';
import { Box, Theme, useMediaQuery } from '@mui/material';
import useMainParticipant from '../../../hooks/twilio/useMainParticipant/useMainParticipant';
import useParticipants from '../../../hooks/twilio/useParticipants/useParticipants';
import { useVideoContext } from '../../../providers/video';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../../hooks/twilio/useScreenShareParticipant/useScreenShareParticipant';
import { useEffect } from 'react';

export default function ParticipantList() {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { room } = useVideoContext();
    const localParticipant = room!.localParticipant;
    const participants = useParticipants();
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
    const screenShareParticipant = useScreenShareParticipant();
    const mainParticipant = useMainParticipant();
    const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

    if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

    return (
        <Box
            component='aside'
            sx={{
                overflowY: 'auto',
                background: 'rgb(79, 83, 85)',
                gridArea: '1 / 2 / 1 / 3',
                zIndex: 5,
                ...(isMobile && {
                    gridArea: '2 / 1 / 3 / 3',
                    overflowY: 'initial',
                    overflowX: 'auto',
                    display: 'flex'
                }),
                ...(!isRemoteParticipantScreenSharing && {
                    background: 'transparent'
                })
            }}
            id='participant-list'
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Box
                    sx={{ width: `calc(${300}px - 3em)`, padding: '1.5em 0', ...(isMobile && { width: 'auto', padding: `${8}px`, display: 'flex' }) }}
                >
                    <Participant participant={localParticipant} isLocalParticipant={true} />
                    {participants.map((participant) => {
                        const isSelected = participant === selectedParticipant;
                        const hideParticipant = participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
                        return (
                            <Participant
                                key={participant.sid}
                                participant={participant}
                                isSelected={participant === selectedParticipant}
                                onClick={() => setSelectedParticipant(participant)}
                                hideParticipant={hideParticipant}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
}
