import { Box } from '@mui/material';

interface IntroContainerProps {
    children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgb(40, 42, 43)',
                height: '100%'
            }}
        >
            <Box sx={{ position: 'relative', flex: '1' }}>
                <Box
                    sx={{
                        display: { xs: 'block', sm: 'flex' },
                        width: { xs: 'calc(100% - 40px)', sm: '500px' },
                        height: { xs: 'auto', sm: '380px' },
                        borderRadius: '8px',
                        boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
                        overflow: 'hidden',
                        position: 'relative',
                        margin: 'auto',
                        maxWidth: { xs: '400px', sm: 'unset' }
                    }}
                >
                    <Box
                        sx={{
                            background: 'white',
                            width: '100%',
                            padding: { xs: '2em', sm: '2.5em' },
                            flex: 1
                        }}
                    >
                        {props.children}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default IntroContainer;
