import { useCallback, useRef } from 'react';

import { Button, Theme, useMediaQuery, Tooltip } from '@mui/material';

import useDevices from '../../../../hooks/twilio/useDevices/useDevices';
import useLocalVideoToggle from '../../../../hooks/twilio/useLocalVideoToggle/useLocalVideoToggle';
import VideocamSharpIcon from '@mui/icons-material/VideocamSharp';
import VideocamOffSharpIcon from '@mui/icons-material/VideocamOffSharp';

interface ToggleVideoButtonProps {
    isDisabled?: boolean;
    includeBorder?: boolean;
}

export default function ToggleVideoButton({ isDisabled, includeBorder }: ToggleVideoButtonProps) {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const lastClickTimeRef = useRef(0);
    const { hasVideoInputDevices } = useDevices();

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }
    }, [toggleVideoEnabled]);

    let toolTipMessage = isVideoEnabled ? 'Camera off' : 'Camera on';

    return (
        <Tooltip placement='top' title={toolTipMessage} arrow>
            <span>
                <Button
                    sx={{
                        margin: '1em 0',
                        width: '100%',
                        ...(isMobile && {
                            padding: '0.8em 0',
                            margin: 0
                        }),
                        ...(!includeBorder && {
                            color: 'white'
                        }),
                        ...(includeBorder && {
                            border: '2px solid'
                        })
                    }}
                    onClick={toggleVideo}
                    disabled={!hasVideoInputDevices || isDisabled}
                    startIcon={isVideoEnabled ? <VideocamSharpIcon /> : <VideocamOffSharpIcon />}
                ></Button>
            </span>
        </Tooltip>
    );
}
