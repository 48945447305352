import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from '../../icons/video/AvatarIcon';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import { Typography, Box } from '@mui/material';

import useIsTrackSwitchedOff from '../../../hooks/twilio/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useParticipantIsReconnecting from '../../../hooks/twilio/useParticipantIsReconnecting/useParticipantIsReconnecting';
import usePublications from '../../../hooks/twilio/usePublications/usePublications';
import useScreenShareParticipant from '../../../hooks/twilio/useScreenShareParticipant/useScreenShareParticipant';
import useTrack from '../../../hooks/twilio/useTrack/useTrack';
import { useVideoContext } from '../../../providers/video';

interface MainParticipantInfoProps {
    participant: Participant;
    children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
    const { room } = useVideoContext();
    const localParticipant = room!.localParticipant;
    const isLocal = localParticipant === participant;

    const screenShareParticipant = useScreenShareParticipant();
    const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

    const publications = usePublications(participant);
    const videoPublication = publications.find((p) => !p.trackName.includes('screen') && p.kind === 'video');
    const screenSharePublication = publications.find((p) => p.trackName.includes('screen'));

    const videoTrack = useTrack(screenSharePublication || videoPublication);
    const isVideoEnabled = Boolean(videoTrack);

    const audioPublication = publications.find((p) => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;

    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                ...(!isRemoteParticipantScreenSharing && {
                    gridArea: { xs: '1 / 1 / 2 / 3', md: '1 / 1 / 3 / 3' }
                })
            }}
            data-cy-main-participant
            data-cy-participant={participant.identity}
        >
            <Box
                sx={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%'
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <Box
                        sx={{
                            background: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            padding: '0.1em 0.3em 0.1em 0',
                            display: 'inline-flex',
                            '& svg': {
                                marginLeft: '0.3em'
                            },
                            marginRight: '0.4em',
                            alignItems: 'center'
                        }}
                    >
                        <AudioLevelIndicator audioTrack={audioTrack} />
                        <Typography variant='body1' color='inherit'>
                            {participant.identity}
                            {isLocal && ' (You)'}
                            {screenSharePublication && ' - Screen'}
                        </Typography>
                    </Box>
                    <NetworkQualityLevel participant={participant} />
                </Box>
            </Box>
            {(!isVideoEnabled || isVideoSwitchedOff) && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'black',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                        '& svg': {
                            transform: 'scale(2)'
                        }
                    }}
                >
                    <AvatarIcon />
                </Box>
            )}
            {isParticipantReconnecting && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(40, 42, 43, 0.75)',
                        zIndex: 1
                    }}
                >
                    <Typography variant='body1' sx={{ color: 'white' }}>
                        Reconnecting...
                    </Typography>
                </Box>
            )}
            {children}
        </Box>
    );
}
