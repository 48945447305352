import MenuBar from "./MenuBar/MenuBar";
import MobileTopMenuBar from "./MobileTopMenuBar/MobileTopMenuBar";
import PreJoinScreens from "./PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from "./ReconnectingNotification/ReconnectingNotification";
import Room from "./Room/Room";

import useRoomState from "../../hooks/twilio/useRoomState/useRoomState";
import { useVideoState } from "../../providers/twilio";
import { useVideoContext } from "../../providers/video";

import Box from "@mui/material/Box";

export default function Twilio({ roomId }: { roomId?: String }) {
  const roomState = useRoomState();
  const { room, isAcquiringLocalTracks } = useVideoContext();
  const { appointment } = useVideoState();

  console.log(room);

  return (
    <div
      style={{ height: "100%", display: "grid", gridTemplateRows: "1fr auto" }}
    >
      {roomState === "disconnected" || isAcquiringLocalTracks || !room ? (
        <PreJoinScreens appointment={appointment} room={roomId?.toString()} />
      ) : (
        <Box
          id="twilio-container"
          component="main"
          sx={{
            overflow: "hidden",
            background: "black",
            paddingBottom: "72px",
          }}
        >
          <ReconnectingNotification />
          <Room />
          <MenuBar />
        </Box>
      )}
    </div>
  );
}
