import { useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Theme,
  Hidden,
  CircularProgress,
  useMediaQuery,
  Box,
  Stack,
} from "@mui/material";

import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import { Steps } from "../../../../types/twilio";
import ToggleAudioButton from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import { useVideoState } from "../../../../providers/twilio";
import { useVideoContext } from "../../../../providers/video";
import { useProfile } from "../../../../providers/profile";
import { useSnackbar } from "notistack";
import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";
import { LocalAudioTrack } from "twilio-video";

const crypto = require("crypto");

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string; //room from query string, if one was passed
  displayName: string;
  setStep: (step: Steps) => void;
  appointmentId?: number;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
  displayName,
  setStep,
  appointmentId,
}: DeviceSelectionScreenProps) {
  const sha256 = (x: String) =>
    crypto.createHash("sha256").update(x, "utf8").digest("hex");
  const { isAuthenticated } = useAuth0();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { isFetching, setVideoOpen } = useVideoState();
  const {
    account,
    updateLessonStatus,
    isInLesson,
    activeLesson,
    updateActiveLesson,
  } = useProfile();
  const [busy, setBusy] = useState(false);
  const joinOnLoad = useRef(isInLesson && activeLesson);
  const { enqueueSnackbar } = useSnackbar();

  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
    localTracks,
    getLocalAudioTrack,
    room,
  } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const router = useRouter();

  const handleJoin = useCallback(async () => {
    setBusy(true);
    const displayName =
      account !== undefined && account.name ? account.name : name ?? "Guest";
    const lessonRoomName = roomName
      ? roomName
      : activeLesson
      ? sha256(activeLesson.toString())
      : "";

    try {
      const result = await fetch("/api/twilio/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identity: displayName,
          room: lessonRoomName,
        }),
      });
      const data = await result.json();

      if (!data || !data.twilioToken) throw "cannot join a call";

      videoConnect(data.twilioToken);

      // @ts-ignore
      if (typeof window !== "undefined") {
        // @ts-ignore
        window.addEventListener("visibilitychange", tabFocused);
      }

      updateLessonStatus(true);
      if (roomName && !activeLesson) {
        updateActiveLesson(appointmentId);
      }

      setBusy(false);
      if (isAuthenticated && account) {
        router.push("/chat");
      }
    } catch (error) {
      enqueueSnackbar(
        "Problem scheduling lesson, please try again or refresh.",
        { variant: "error" }
      );
      setBusy(false);
    }
  }, [
    account,
    activeLesson,
    roomName,
    videoConnect,
    updateLessonStatus,
    updateActiveLesson,
    setBusy,
    enqueueSnackbar,
    localTracks,
  ]);

  const tabFocused = () => {
    console.log("tab focused");
    console.log(localTracks);
    const audioTrack = localTracks.find(
      (track) => track.kind === "audio"
    ) as LocalAudioTrack;

    console.log(audioTrack);

    if (!audioTrack) {
      getLocalAudioTrack()
        .then((track: LocalAudioTrack) =>
          room?.localParticipant?.publishTrack(track, { priority: "low" })
        )
        .catch(() => {
          console.log("encountered an error publishing an audio track");
        })
        .finally(() => {
          console.log("Re-published local audio track");
        });
    } else if (audioTrack && !audioTrack.isEnabled) {
      audioTrack.enable();
    } else {
      console.log("audio should work");
    }
  };

  const handleCancel = useCallback(() => {
    setStep(Steps.roomNameStep);
    setVideoOpen(false);
    updateLessonStatus(false);
    updateActiveLesson(undefined);

    if (room) {
      room.disconnect();
    }

    if (localTracks?.length > 0) {
      localTracks.forEach((track) => track.stop());
    }
  }, [
    setStep,
    setVideoOpen,
    updateLessonStatus,
    updateActiveLesson,
    room,
    localTracks,
  ]);

  useEffect(() => {
    if (joinOnLoad.current && activeLesson && !isAcquiringLocalTracks) {
      handleJoin();
    }
  }, [joinOnLoad.current, activeLesson, isAcquiringLocalTracks]);

  if (busy || isConnecting) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: "1em" }}>
        Join {displayName}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <Box
            sx={{
              paddingRight: "1.25em",
              ...(isMobile && {
                padding: "0 2.5em",
              }),
            }}
          >
            <LocalVideoPreview identity={name} />
          </Box>
          <Box
            sx={{
              ...(isMobile && {
                display: "flex",
                justifyContent: "space-between",
                margin: "1.5em 0 1em",
              }),
            }}
          >
            <Hidden mdUp>
              <ToggleAudioButton
                isDisabled={disableButtons}
                includeBorder={true}
              />
              <ToggleVideoButton
                isDisabled={disableButtons}
                includeBorder={true}
              />
            </Hidden>
            <SettingsMenu />
          </Box>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{ height: "100%" }}
          >
            <div>
              <Hidden smDown>
                <Stack>
                  <ToggleAudioButton
                    isDisabled={disableButtons}
                    includeBorder={true}
                  />
                  <ToggleVideoButton
                    isDisabled={disableButtons}
                    includeBorder={true}
                  />
                </Stack>
              </Hidden>
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                ...(isMobile && {
                  flexDirection: "column-reverse",
                  width: "100%",
                  "& button": {
                    margin: "0.5em 0",
                  },
                }),
              }}
            >
              <Button variant="outlined" color="primary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
              >
                Join Now
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
