import { Typography, Button, Grid, Theme, useMediaQuery, TextField } from '@mui/material';
import useSWR from 'swr';
import { fetcher } from '../../../../utilities/fetcher';
import { useAuth0 } from '@auth0/auth0-react';
import { useProfile } from '../../../../providers/profile';
import { useEffect, useState } from 'react';

interface RoomNameScreenProps {
    name: string;
    roomName: string;
    roomDisplayName: string;
    handleSubmit: (event: any, name?: string) => void;
}

export default function RoomNameScreen({ name, roomName, roomDisplayName, handleSubmit }: RoomNameScreenProps) {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { getAccessTokenSilently } = useAuth0();
    const { account } = useProfile();
    const [guestName, setGuestName] = useState('');

    // for now, we check if user can join and/or create a room based on following rules
    // 1. Any user other that student can join, b/c they will be able to create a room if one doesn't already exist
    // 2. If user is student, we check if room exists. If yes, they can join, if not, they need to come back when it exists
    const { data: canJoin } = useSWR(
        roomName ? `/api/twilio/room/${encodeURIComponent(roomName as string)}` : null,
        (url) => fetcher(url, getAccessTokenSilently),
        {
            refreshInterval: 5000
        }
    );

    if (
        roomName !== 'AppleTestRoom' &&
        account &&
        !canJoin &&
        !account?.roles?.some((r) => ['teacher', 'admin', 'superadmin'].includes(r as string))
    ) {
        return (
            <Grid container justifyContent='center' alignItems='center' direction='column' style={{ height: '100%' }}>
                <div>
                    <Typography variant='body2' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        Your lesson has not started yet. Please wait for your teacher to begin the lesson.
                    </Typography>
                </div>
            </Grid>
        );
    }

    return (
        <>
            <Typography variant='h5'>{roomDisplayName}</Typography>
            {!account && (
                <TextField
                    id='guest-name'
                    label='Guest Name'
                    variant='filled'
                    value={guestName}
                    onChange={(e: any) => setGuestName(e.target.value)}
                    helperText='Please enter your name'
                    required
                />
            )}
            <Typography
                sx={{
                    marginBottom: '1em'
                }}
                variant='h6'
            >
                Join a Lesson
            </Typography>
            <Button
                sx={{
                    ...(isMobile && {
                        width: '100%'
                    })
                }}
                onClick={(e) => handleSubmit(e, guestName)}
                variant='contained'
                disabled={(!guestName && !name) || !roomName}
            >
                Continue
            </Button>
        </>
    );
}
