import { useState, useRef } from 'react';
import AboutDialog from '../../AboutDialog/AboutDialog';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import { MoreVert } from '@mui/icons-material';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import InfoIconOutlined from '../../../icons/video/InfoIconOutlined';
import SettingsIcon from '../../../icons/video/SettingsIcon';
import { Button, Theme, Menu as MenuContainer, MenuItem, Typography, useMediaQuery, Box } from '@mui/material';

import FlipCameraIcon from '../../../icons/video/FlipCameraIcon';
import useFlipCameraToggle from '../../../../hooks/twilio/useFlipCameraToggle/useFlipCameraToggle';

export default function Menu() {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const [aboutOpen, setAboutOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);

    const anchorRef = useRef<HTMLButtonElement>(null);
    const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

    return (
        <>
            <Button
                sx={{
                    color: 'white',
                    ...(isMobile && {
                        height: '28px',
                        minWidth: '28px',
                        border: '1px solid rgb(136, 140, 142)',
                        padding: 0,
                        margin: '0 1em'
                    })
                }}
                onClick={() => setMenuOpen((isOpen) => !isOpen)}
                ref={anchorRef}
                data-cy-more-button
            >
                {isMobile ? (
                    <MoreVert />
                ) : (
                    <>
                        <SettingsTwoToneIcon />
                    </>
                )}
            </Button>
            <MenuContainer
                open={menuOpen}
                onClose={() => setMenuOpen((isOpen) => !isOpen)}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: isMobile ? -55 : 'bottom',
                    horizontal: 'center'
                }}
            >
                <MenuItem onClick={() => setSettingsOpen(true)}>
                    <Box
                        component='div'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '1.5em',
                            marginRight: '0.3em'
                        }}
                    >
                        <SettingsIcon />
                    </Box>
                    <Typography variant='body1'>Audio and Video Settings</Typography>
                </MenuItem>

                {flipCameraSupported && (
                    <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
                        <Box
                            component='div'
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '1.5em',
                                marginRight: '0.3em'
                            }}
                        >
                            <FlipCameraIcon />
                        </Box>
                        <Typography variant='body1'>Flip Camera</Typography>
                    </MenuItem>
                )}

                <MenuItem onClick={() => setAboutOpen(true)}>
                    <Box
                        component='div'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '1.5em',
                            marginRight: '0.3em'
                        }}
                    >
                        <InfoIconOutlined />
                    </Box>
                    <Typography variant='body1'>About</Typography>
                </MenuItem>
            </MenuContainer>
            <AboutDialog
                open={aboutOpen}
                onClose={() => {
                    setAboutOpen(false);
                    setMenuOpen(false);
                }}
            />
            <DeviceSelectionDialog
                open={settingsOpen}
                onClose={() => {
                    setSettingsOpen(false);
                    setMenuOpen(false);
                }}
            />
        </>
    );
}
