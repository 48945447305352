import AvatarIcon from '../../../../icons/video/AvatarIcon';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import { useVideoContext } from '../../../../../providers/video';

export default function LocalVideoPreview({ identity }: { identity: string }) {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const { localTracks } = useVideoContext();

    const videoTrack = localTracks.find((track) => !track.name.includes('screen') && track.kind === 'video') as LocalVideoTrack;

    return (
        <Box
            sx={{
                position: 'relative',
                height: 0,
                overflow: 'hidden',
                paddingTop: `${(9 / 16) * 100}%`,
                background: 'black'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
            >
                {videoTrack ? (
                    <VideoTrack track={videoTrack} isLocal />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'black',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: 1,
                            ...(isMobile && {
                                '& svg': {
                                    transform: 'scale(0.7)'
                                }
                            })
                        }}
                    >
                        <AvatarIcon />
                    </Box>
                )}
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    zIndex: 1
                }}
            >
                <Box
                    sx={{
                        background: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        padding: '0.18em 0.3em',
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    component='span'
                >
                    <LocalAudioLevelIndicator />
                    <Typography variant='body1' color='inherit' component='span'>
                        {identity}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
