import { useState, useRef } from 'react';
import { Button, MenuItem, Typography, Theme, Menu } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

import AboutDialog from '../../../AboutDialog/AboutDialog';
import ConnectionOptionsDialog from '../../../ConnectionOptionsDialog/ConnectionOptionsDialog';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
import SettingsIcon from '../../../../icons/video/SettingsIcon';
import { useVideoState } from '../../../../../providers/twilio';

export default function SettingsMenu() {
    const { roomType } = useVideoState();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [menuOpen, setMenuOpen] = useState(false);
    const [aboutOpen, setAboutOpen] = useState(false);
    const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
    const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);

    const anchorRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            {isMobile ? (
                <Button
                    sx={{
                        padding: '0.8em 0',
                        margin: 0
                    }}
                    ref={anchorRef}
                    onClick={() => setMenuOpen(true)}
                    startIcon={<MoreHoriz />}
                >
                    More
                </Button>
            ) : (
                <Button ref={anchorRef} onClick={() => setMenuOpen(true)} startIcon={<SettingsIcon />} sx={{ margin: '1.8em 0 0' }}>
                    Settings
                </Button>
            )}
            <Menu
                open={menuOpen}
                onClose={() => setMenuOpen((isOpen) => !isOpen)}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: isMobile ? 'left' : 'right'
                }}
                transformOrigin={{
                    vertical: 0,
                    horizontal: 'center'
                }}
            >
                <MenuItem onClick={() => setAboutOpen(true)}>
                    <Typography variant='body1'>About</Typography>
                </MenuItem>
                <MenuItem onClick={() => setDeviceSettingsOpen(true)}>
                    <Typography variant='body1'>Audio and Video Settings</Typography>
                </MenuItem>
                {roomType !== 'peer-to-peer' && roomType !== 'go' && (
                    <MenuItem onClick={() => setConnectionSettingsOpen(true)}>
                        <Typography variant='body1'>Connection Settings</Typography>
                    </MenuItem>
                )}
            </Menu>
            <AboutDialog
                open={aboutOpen}
                onClose={() => {
                    setAboutOpen(false);
                    setMenuOpen(false);
                }}
            />
            <DeviceSelectionDialog
                open={deviceSettingsOpen}
                onClose={() => {
                    setDeviceSettingsOpen(false);
                    setMenuOpen(false);
                }}
            />
            <ConnectionOptionsDialog
                open={connectionSettingsOpen}
                onClose={() => {
                    setConnectionSettingsOpen(false);
                    setMenuOpen(false);
                }}
            />
        </>
    );
}
