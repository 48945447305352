import { Theme, Button, Tooltip, useMediaQuery } from "@mui/material";
import CallEndTwoToneIcon from "@mui/icons-material/CallEndTwoTone";

import { useVideoContext } from "../../../../providers/video";
import { useProfile } from "../../../../providers/profile";
import { useCallback } from "react";
import { useRouter } from "next/router";
import { useVideoState } from "../../../../providers/twilio";
import { Steps } from "../../../../types/twilio";
import { useAuth0 } from "@auth0/auth0-react";

export default function EndCallButton() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { room, localTracks } = useVideoContext();
  const {
    account,
    viewProfile,
    selectViewProfile,
    updateLessonStatus,
    updateActiveLesson,
  } = useProfile();
  const router = useRouter();
  const { setRoomStep, setVideoOpen } = useVideoState();
  const { isAuthenticated } = useAuth0();

  const endCall = useCallback(() => {
    localTracks.forEach((track) => track.stop());

    if (room) {
      room.disconnect();
    }

    updateLessonStatus(false);
    updateActiveLesson(undefined);
    setRoomStep(Steps.roomNameStep);
    setVideoOpen(false);

    if (
      isAuthenticated &&
      account &&
      account?.roles?.some((r) =>
        ["teacher", "admin", "superadmin"].includes(r as string)
      )
    ) {
      router.push("/teacher/lessons");
    } else if (isAuthenticated && account) {
      router.push("/lessons");
    }
  }, [
    localTracks,
    room,
    viewProfile,
    selectViewProfile,
    updateLessonStatus,
    updateActiveLesson,
  ]);

  return (
    <Tooltip placement="top" title="End Lesson" arrow>
      <Button
        sx={{
          background: "#E22525",
          color: "white",
          "&:hover": {
            background: "#600101",
          },
          ...(isMobile && {
            height: "28px",
            fontSize: "0.85rem",
            padding: "0 0.6em",
          }),
        }}
        startIcon={<CallEndTwoToneIcon />}
        onClick={endCall}
        data-cy-disconnect
      ></Button>
    </Tooltip>
  );
}
