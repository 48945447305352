import { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useVideoState } from '../../../providers/twilio';
import { useVideoContext } from '../../../providers/video';
import { useProfile } from '../../../providers/profile';
import { Steps } from '../../../types/twilio';
import { Appointment } from '../../../types/acuity';

const crypto = require('crypto');

export default function PreJoinScreens({ appointment, room }: { appointment?: Appointment; room?: string }) {
    const sha256 = (x: String) => crypto.createHash('sha256').update(x, 'utf8').digest('hex');
    const { account } = useProfile();
    const { getAudioAndVideoTracks, isAcquiringLocalTracks } = useVideoContext();
    const { roomStep, setRoomStep } = useVideoState();

    const [userName, setUserName] = useState<string>(account?.name || '');
    const [roomName, setRoomName] = useState<string>(appointment?.id ? sha256(appointment.id.toString()) : '');
    const [roomDisplayName] = useState<string>(appointment?.type ? `${appointment.type}` : '');

    const [mediaError, setMediaError] = useState<Error>();
    const [hasTriedGettingLocalTracks, setHasTriedGettingLocalTracks] = useState<Boolean>(false);

    useEffect(() => {
        if (room !== undefined && room !== '') {
            setRoomName(room);
            setRoomStep(Steps.roomNameStep);
        }
    }, [room, setRoomName, setRoomStep]);

    useEffect(() => {
        if (roomStep === Steps.deviceSelectionStep && !mediaError && !isAcquiringLocalTracks && !hasTriedGettingLocalTracks) {
            getAudioAndVideoTracks().catch((error) => {
                console.log('Error acquiring local media:');
                console.dir(error);
                setMediaError(error);
            });
            setHasTriedGettingLocalTracks(true);
        }
    }, [getAudioAndVideoTracks, roomStep, mediaError, isAcquiringLocalTracks, hasTriedGettingLocalTracks, setHasTriedGettingLocalTracks]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>, name?: string) => {
        event.preventDefault();
        if (name && name !== '') {
            setUserName(name);
        }
        setRoomStep(Steps.deviceSelectionStep);
    };

    return (
        <IntroContainer>
            {roomStep === Steps.roomNameStep && (
                <RoomNameScreen name={userName} roomName={roomName} roomDisplayName={roomDisplayName} handleSubmit={handleSubmit} />
            )}

            {roomStep === Steps.deviceSelectionStep && hasTriedGettingLocalTracks && (
                <DeviceSelectionScreen
                    name={userName}
                    roomName={roomName}
                    displayName={roomDisplayName}
                    setStep={setRoomStep}
                    appointmentId={appointment?.id}
                />
            )}
        </IntroContainer>
    );
}
