import { useEffect, useState } from 'react';
import { useVideoContext } from '../../../providers/video';

type RoomStateType = 'disconnected' | 'connected' | 'reconnecting';

export default function useRoomState() {
    const { room } = useVideoContext();
    const [state, setState] = useState<RoomStateType>('disconnected');

    useEffect(() => {
        const setRoomState = () => setState(room?.state as RoomStateType);
        if (room) {
            setRoomState();
            room.on('disconnected', setRoomState).on('reconnected', setRoomState).on('reconnecting', setRoomState);
            return () => {
                room.off('disconnected', setRoomState).off('reconnected', setRoomState).off('reconnecting', setRoomState);
            };
        }
    });

    return state;
}
